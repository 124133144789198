<template>
  <div>
    <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <md-icon>list_alt</md-icon>
            <h3 class="pl-2 text-uppercase">Package</h3>
          </div>
          <md-button
              class="bg-green text-white rounded-md md-dense m-0 text-uppercase"
              :to="{ name: 'packageCreation.basicInformation' }"
          >
            <md-icon class="text-white">add</md-icon> Create New Package
          </md-button>
        </div>
      </template>
      <CardBody class="p-3">
        <div class="mb-3 flex justify-between flex-wrap justify-md-center justify-sm-center justify-xs-center">
          <!-- <SearchBox class="w-64 p-2 m-2" /> -->
        </div>
        <md-divider></md-divider>

        <Table :items="packageList">
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <md-table-cell md-sort-by="id" md-label="SL">{{item.sl}}</md-table-cell>
            <md-table-cell md-label="Name">{{ item.name }}</md-table-cell>
            <!-- <md-table-cell md-label="Type">{{ item.type }}</md-table-cell> -->
            <md-table-cell md-label="Online Link">{{ item.online_link }}</md-table-cell>
            <md-table-cell md-label="Monthly New Price">{{ item.package_detail?item.package_detail.monthly_new_price:'' }}</md-table-cell>
            <md-table-cell md-label="Monthly Old Price">{{ item.package_detail?item.package_detail.monthly_old_price:'' }}</md-table-cell>
            <md-table-cell md-label="Weekly New Price">{{ item.package_detail?item.package_detail.weekly_new_price:'' }}</md-table-cell>
            <md-table-cell md-label="Weekly Old Price">{{ item.package_detail?item.package_detail.weekly_old_price:'' }}</md-table-cell>
            <md-table-cell md-label="Created At">{{ item.package_detail?customFormatter(item.package_detail.created_at):'' }}</md-table-cell>
            <md-table-cell>
              <md-icon
                  class="bg-default rounded-full p-1 text-gray-700 text-base mr-1"
                  @click.native="onShowDetails(item.id)"
              >visibility</md-icon>
              <!-- <md-icon class="bg-victoria rounded-full p-1 text-white text-base " @click.native="onEditSelect(item)">edit</md-icon> -->
            </md-table-cell>
          </template>
        </Table>
        <div class="flex justify-content-center align-center mt-8">
          <Paginate
              :start="meta.current_page"
              :end="meta.last_page"
              :total="meta.last_page"
              :limit="meta.per_page"
              @on-start="onStart"
              @on-end="onEnd"
          />
        </div>

      </CardBody>

      <Dialog>
        <!-- <TheOnlineClassCreate /> -->
        <components :content="content" :is="component"></components>
      </Dialog>

    </Card>
  </div>
</template>

<script>
import { Card, CardBody, Table, Dialog, Paginate } from "@/components";
import SearchBox from "@/components/atom/SearchBox";
import ShowPackageDetails from "@/components/molecule/package/ShowPackageDetails";
import data from "@/data/form/data";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { query } from "@/utils/queryString";
import moment from 'moment';
export default {
  name: "Form-List-View",
  components: {
    Card,
    CardBody,
    SearchBox,
    Table,
    Dialog,
    ShowPackageDetails,
    Paginate
  },
  data() {
    return {
      query: '?',
      currentIndex: 0,
      coachingCenter: [],
      forms: data.forms,
      content: {},
      component: '',
    };
  },
   computed: {
      ...mapGetters({
        packageList: "packages/packageList",
        meta: "packages/packageMeta",
      }),
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
      ...mapActions({
      actGetPackageList: 'packages/actGetPackageList',
      actGetPackageByPackageId: "packages/actGetPackageByPackageId",
    }),
    customFormatter(date) {
      return moment(date).format('D MMM, YYYY');
    },
    onEditSelect(item) {
      this.$router.push({
        name: "admin.course.create",
        params: {
          id: item.id,
        },
      });
    },
    onShowDetails(id) {

      this.component = 'ShowPackageDetails';
      this.actGetPackageByPackageId(id)
      .then( ({items}) => {
          this.content = items;
          this.dialog(true);
      })
      .catch(error => console.log(error))
      
      
    },

    onStart(value) {
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actGetPackageList(`?type=PACKAGE&${query.get()}`);
    },
    onEnd(value) {
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actGetPackageList(`?type=PACKAGE&${query.get()}`);
    },
  },

  created(){
    this.actGetPackageList(`?type=PACKAGE`);
  }
};
</script>
<template>
  <div>
    <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <md-icon>list_alt</md-icon>
            <h3 class="pl-2 text-uppercase">Admin Users</h3>
          </div>
          <md-button
              class="bg-green text-white rounded-md md-dense m-0 text-uppercase"
              :to="{ name: 'master.createAdministrative' }"
          >
            <md-icon class="text-white">add</md-icon> Create New Admin User
          </md-button>
        </div>
      </template>
      <CardBody class="p-3">
        <div class="mb-3 flex align-center justify-between flex-wrap justify-xs-center">
          <div class="inline-flex flex-wrap justify-sm-center justify-xs-center">
            <select-component
              class='w-full'
              iconClass='h-4'
              :classes="['py-2']"
              :items="StatusType"
              return-type="object"
              :placeholder="'Select Status'"
              @getObject="getStatus"
              :value="selectedStatus"
            />
          </div>
          <div>
            <SearchBox v-model="keyword" @submit="submitSearchKeyword" class="w-64 p-1 pl-3" placeholder="Search Name,Email"/>
          </div>
        </div>
        <md-divider></md-divider>
        <Table :sort-by="default_sort" :items="administrativeRolesList" hover-action @onSort="setSortQuestion" style="min-height: 70vh;" class="table-row">
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <!-- <md-table-cell md-label="SL">{{item.sl}}</md-table-cell> -->
            <md-table-cell md-sort-by="name" md-label="Name">{{ item.name ?? '-'}}</md-table-cell>
            <md-table-cell md-sort-by="email" md-label="Email">{{ item.email ?? '-'}}</md-table-cell>
            <md-table-cell md-label="Phone Number"><span v-if="item.user_profile">{{  item.user_profile.contact_number ?? '-'}}</span></md-table-cell>
            <md-table-cell md-sort-by="create_at" md-label="Created At">{{ item.created_at ?? '-'}}</md-table-cell>
            <md-table-cell md-sort-by="active" md-label="Status">
              <md-switch 
                :value="!item.active" 
                 @change="toggleStatus(item)"
                :md-inactive="item.active === 0"
                :md-active="item.active === 1"
                class="m-0"
                :disabled="item.isLoading"
                >
              </md-switch>
            </md-table-cell>
            <md-table-cell>
              <md-icon @click.native="onEditDetails(item.user_id)" class="bg-default rounded-full p-1 text-gray-700 text-base mr-1">edit</md-icon>
              <md-icon @click.native="deleteUser(item.user_id)" class="bg-danger rounded-full p-1 text-white text-base mx-1">delete</md-icon>
            </md-table-cell>
          </template>
        </Table>
        <div class="flex justify-end align-center mt-8">
          <Paginate
              :start="meta.from"
              :end="meta.to"
              :next="meta.current_page"
              :prev="meta.last_page"
              :total="meta.total"
              :limit="meta.per_page"
              @on-start="onStart"
              @on-end="onEnd"
          />
        </div>
      </CardBody>
      <Spinner :loading="isLoading" :diameter="40" :stroke="3" />
      <ConfirmDialog 
        @confirm="actDeleteUser(userId)"
        :active.sync="showDeleteModal"
        classes="delete-modal"
      />
    </Card>
  </div>
</template>

<script>
import { Card, CardBody, Table, Paginate, ConfirmDialog, Spinner } from "@/components";
import SearchBox from "@/components/atom/SearchBox";
import { mapMutations, mapGetters, mapActions, mapState } from "vuex";
import { query } from "@/utils/queryString";
import moment from 'moment';
import { SelectComponent } from "@/components";

export default {
  name: "Form-List-View",
  components: {
    Card,
    CardBody,
    SearchBox,
    Table,
    Paginate,
    ConfirmDialog,
    SelectComponent,
    Spinner
  },
  data() {
    return {
      query: '?',
      showDeleteModal: false,
      status_type: 'Select',
      content: {},
      keyword: '',
      selectedStatus: '',
      default_sort: 'name',
      userId: null,
      StatusType: [
        { id: 2, name: "All" },
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ]
    };
  },
  computed: {
      ...mapGetters({
        administrativeRolesList: "user/userList",
        meta: "user/userMeta",
      }),
      ...mapState({
        isLoading: state => state.user.isLoading,
      }),
  },
  watch: {
    keyword(previous, current) {
        query.set('search', previous)
        if(previous.length < current.length && previous.length == 0) {
          this.actGetUserList(`?type=SUPER_ADMIN&${query.get()}`);
        }
        if(previous.length >= 3) {
          this.actGetUserList(`?type=SUPER_ADMIN&${query.get()}`);
        }
    }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar",
    }),
      ...mapActions({
      actGetUserList: 'user/actGetUserList',
      actGetUserByUserId: "user/actGetUserByUserId",
      actDeleteUser: "user/actDeleteUserByUserId",
      actChangeStatusUser: "user/actChangeStatusUser",
    }),
    customFormatter(date) {
      return date ? moment(date).format('D MMM, YYYY') : '-';
    },
    getStatus(item) {
      this.selectedStatus = item.id;
      this.selectedStatus === 2 ? query.remove('active') : query.set('active', this.selectedStatus); 
      query.set('page', 1) 
      this.actGetUserList(`?type=SUPER_ADMIN&${query.get()}`);
    },
    submitSearchKeyword() {
      this.keyword ? query.set('search', this.keyword) : query.remove('search'); 
      this.keyword && query.set('page', 1) 
      this.actGetUserList(`?type=SUPER_ADMIN&${query.get()}`);
    },
    setSortQuestion(sort){
      const sortString = sort.order === 'asc' ? sort.sortBy : `-${sort.sortBy}`;
      query.set('sort', sortString);
      this.actGetUserList(`?type=SUPER_ADMIN&${query.get()}`);
    },
    onShowDetails(id) {
      this.component = 'ShowStudentDetails';
      this.actGetUserByUserId(id)
      .then( ({items}) => {
          this.content = items;
          this.dialog(true);
      })
      .catch(error => console.log(error))
    },
    onEditDetails (id) {
      this.$router.push({
        name: 'master.editAdministrative',
        params: {
          id: id
        },
      })
    },
    deleteUser(userId) {
      this.showDeleteModal = true;
      this.userId = userId;
    },
    onStart(value) {
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actGetUserList(`?type=SUPER_ADMIN&${query.get()}`);
    },
    onEnd(value) {
      console.log(value);
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actGetUserList(`?type=SUPER_ADMIN&${query.get()}`);
    },
    toggleStatus(item) {
      if (item.isLoading) return;
      const originalStatus = item.active;
      const updatedStatus = !item.active;

      this.$set(item, "isLoading", true);

      this.$set(item, "active", updatedStatus);

      this.actChangeStatusUser({
        userId: item.user_id,
        payload: {
          active: updatedStatus,
        }
      })
      .then(response => {
        console.log(response)
        this.setShowSnackbar('Status Changed Successfully');
      })
      .catch(error => {
        console.error("Error updating status:", error);
        this.setShowSnackbar(error.message || "Failed to update status.");
        this.$set(item, "active", originalStatus);
      })
      .finally(() => {
        this.$set(item, "isLoading", false);
      });
    },
  },
  created(){
    query.clear()
    query.set('sort', this.default_sort);
    this.actGetUserList(`?type=SUPER_ADMIN&${query.get()}`);
  }
};
</script>
<template>
    <div>
        <div class="flex align-center">
            <div class="flex justify-between align-center mb-4">
               <h2 class="text-uppercase font-bold">{{ this.formMode }} {{ this.componentType === 'STUDENT' ? this.componentType : 'Admin User'}}</h2>
           </div>
        </div>
        <div class="md-layout md-gutter mt-4">
            <form @submit.prevent="handleSubmit" class="w-full">
                <div class="md-layout-item md-size-100">
                    <div class="md-layout-item md-size-100 md-xsmall-size-100 inline-block">   
                        <div class="flex align-center image-card rounded-md d-bg-white">
                            <ImageUploader2 :imageUrl="user.user_profile.image" @avatar-updated="updateImage"/>
                            <div class="inline-block ml-4">
                                <h4>{{ this.user.name }}</h4>
                                <p>{{ this.user.email }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="md-layout-item md-size-50 md-xsmall-size-100 inline-block mt-6">   
                        <InputFieldComponent
                            type="text"
                            :requiredLabel="true"
                            label="First name"
                            class="my-1 align-left"
                            v-model.trim="user.name"
                            :message="($v.user.name.$dirty && !$v.user.name.required ? 'Field is required' : null) || formErrors.name && formErrors.name[0]"
                            :class="{'vuelidate-error': $v.user.name.$error || formErrors.name}"
                        />
                    </div>
                    <div class="md-layout-item md-size-50 md-xsmall-size-100 inline-block mt-6">   
                        <InputFieldComponent
                            type="text"
                            label="Last name"
                            class="my-1 align-left"
                            v-model.trim="user.last_name"
                        />
                    </div>
                    <div class="md-layout-item md-size-50 md-xsmall-size-100 inline-block">   
                        <InputFieldComponent
                            type="text"
                            label="email"
                            :requiredLabel="true"
                            class="my-1 align-left"
                            v-model.trim="user.email"
                            :message="($v.user.email.$dirty && $v.user.email.$error ? 'Invalid email address' : null) || formErrors.email && formErrors.email[0]"
                            :class="{'vuelidate-error': $v.user.email.$error || formErrors.email}"
                        />
                    </div>
                    <div class="md-layout-item md-size-50 md-xsmall-size-100 inline-block">   
                        <InputFieldComponent
                            type="tel"
                            label="Phone Number"
                            :requiredLabel="true"
                            class="my-1 align-left"
                            v-model.trim="user.user_profile.contact_number"
                            :message="($v.user.user_profile.contact_number.$dirty && $v.user.user_profile.contact_number.$error ? 'Invalid phone number' : null) ||  formErrors.contact_number && formErrors.contact_number[0]"
                            :class="{'vuelidate-error': $v.user.user_profile.contact_number.$error || formErrors.contact_number}"
                        />
                    </div>
                    <div class="md-layout-item md-size-50 md-xsmall-size-100 inline-flex">   
                        <Datepicker
                            label="Date of Birth"
                            class="my-1 align-left w-full"
                            v-model.trim="user.user_profile.dob"
                        />
                    </div>
                    <div v-if="this.componentType === 'STUDENT'" class="md-layout-item md-size-50 md-xsmall-size-100 inline-flex">   
                        <Autocomplete
                            :items="organizationList"
                            :requiredLabel="true"
                            label="Coaching Center"
                            class="w-full mb-2"
                            return-type="object"
                            @getObject="setOrganization"
                            placeholder="select Coaching"
                            v-model.trim="user.organizations"
                            :message="$v.user.organizations.$dirty && !$v.user.organizations.required ? 'Field is required' : null"
                            :class="{'vuelidate-error': $v.user.organizations.$error}"
                        />
                    </div>
                </div>
                <div class="md-layout-item md-size-100">
                    <h2 class="m-2 p-4 mt-6 rounded-md d-bg-white mb-4">Address Info</h2>
                    <Address-form ref="address"/>
                </div>
                <div class="flex justify-end mt-6 mr-4">
                    <md-button class="bg-danger text-white rounded" @click="backToPrevious"> Back </md-button>
                    <Button
                      :disabled="disabled"
                      :loading="loading"
                      class="bg-victoria text-white rounded"
                      label="Submit"
                      type="submit"
                  >
                  </Button>
                </div>
            </form>
       </div>
    </div>
  </template>
  
  <script>
  import { InputFieldComponent, Button, ImageUploader2, Datepicker, AddressForm, Autocomplete } from "@/components";
  import { required, email, minLength, maxLength  } from "vuelidate/lib/validators";
  import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
  import { formatDate } from "@/utils/formatDate.js";
  import { scrollToFirstError } from "@/utils/scrollToFirstError";

  export default {
  components: {
      InputFieldComponent,
      Button,
      ImageUploader2,
      Datepicker,
      AddressForm,
      Autocomplete
    },  
    props: {
        componentType: {
        type: String,
        default: '',
        },
    },
    data: () => ({
      loading: false,
      user: {
        name: '',
        last_name: '',
        email: '',
        type: 'SUPER_ADMIN',
        user_profile: {
          image: null,
          dob: '',
          address:null,
          address2: null,
          contact_number: '',
          country_id: '',
        },
        organizations: '',
        organization_id: ''
      },
      disabled: false,
      formErrors: {}
    }),
    computed: {
        ...mapGetters({
            organizationList: "organization/organizationList",
        }),
        ...mapState({
            isLoading: state => state.user.isLoading,
        }),
        userId() {
            return this.$route.params.id
        },
        formMode() {
            return this.$route.params.id ? 'Edit' : 'Create'
        },
        isStudent() {
            return this.componentType === 'STUDENT';
        },
        validationRules() {
            return {
                name: { required },
                email: { required, email },
                organizations: this.isStudent ? { required, $each: { required } } : {},
                user_profile: {
                    contact_number: { required, minLength: minLength(10), maxLength: maxLength(15) },
                },
            };
        }
    },
    methods: {
        ...mapMutations({
          setShowSnackbar: "setShowSnackbar",
        }),
        ...mapActions({
           submitUser: 'user/actCreateNewUser',
           actUpdateUser: 'user/actUpdateUser',
           actGetUserByUserId: "user/actGetUserByUserId",
           actGetOrganizationList: 'organization/actGetOrganizationList',
        }),
        backToPrevious() {
            this.$router.go(-1);
        },
        handleSubmit () {
            if (this.componentType !== 'STUDENT') {
                delete this.user.organizations;
                delete this.user.organization_id;
            }
            if(this.userId && !this.user.password){
                delete this.user.password;
                delete this.user.password_confirmation;
            }
            console.log(this.user.organizations)
            this.loading = true;
            this.$v.$touch();
            const isAddressValid = this.$refs.address.validateForm();
            if(this.$v.$invalid || isAddressValid) {
                setTimeout(()=> {
                    scrollToFirstError()
                },10)
                this.loading = false;
                this.disabled = false;
                return;
            }
            const addressProfile = this.$refs.address.getAddress();
            this.form = {
                name: this.user.name,
                last_name:this.user.last_name,
                email: this.user.email,
                type: this.componentType,
                ...(this.componentType === "STUDENT" && { organizations: [this.user.organization_id] }),
            }
            this.form.user_profile = {
                image: this.user.user_profile.image,
                dob: formatDate(this.user.user_profile.dob),
                address: addressProfile.address,  
                address2: addressProfile.address2,
                contact_number: this.user.user_profile.contact_number,
                country_id: addressProfile.country_id,
                postal_code_id: addressProfile.postal_code_id
            }
           
            this.userId ? (
                this.actUpdateUser({
                    UserId: this.userId,
                    payload: this.form
                })
                .then(() => {
                    this.$router.go(-1)
                    this.loading = false;
                }).catch(error => {
                    if (error.response) {
                      this.formErrors = error.response.data.details;
                    }
                    this.setShowSnackbar(error.response.data.message);
                    this.loading = false;
                })
            ):(
                this.submitUser(this.form)
                .then(response => {
                    console.log(response, 'response')
                    this.$router.go(-1)
                    this.loading = false;
                }).catch(error => {
                    if (error.response) {
                      this.formErrors = error.response.data.details;
                    }
                    this.setShowSnackbar(error.response.data.message);
                    this.loading = false;
                })
                .finally(() => this.loading = false)
            )
        },
        updateImage(image) {
           this.user.user_profile.image = image;
        },
        fetchUserData() {
            this.actGetUserByUserId(this.userId).then(user => {
                this.user.name = user.items.name,
                this.user.last_name = user.items.last_name,
                this.user.email = user.items.email,
                this.user.type = user.items.type
                if(this.userId){
                    let organizations = user.items.organizations.map(item => {
                        return {
                            ...item,              
                            name: item.business_name, 
                        };
                    });
                    this.user.organizations = organizations.length && organizations[0].name || ''
                    this.user.organization_id = organizations.length && organizations[0].id
                }
                if(user.items.user_profile){
                    this.user.user_profile.image = user.items.user_profile.image
                    this.user.user_profile.dob = user.items.user_profile.dob
                    this.user.user_profile.contact_number = user.items.user_profile.contact_number
                    const addressData = { 
                        address: user.items.user_profile.address, 
                        address2: user.items.user_profile.address2,
                        country_id: user.items.user_profile.country_detail.id, 
                        postal_code_id: user.items.user_profile.postal_code_detail.id
                    } 
                    this.$refs.address.setAddress(addressData);
                }
            });
        },
        setOrganization (item) {
            if (item && item.id) {
                console.log(item, 'item')
                this.user.organizations = item.name;
                this.user.organization_id = item.id;
            }
        }
    },
    watch: {
        'user.name'() {
          this.$v.user.name.$touch();
          if (this.formErrors.name) this.$delete(this.formErrors, 'name');
        },
        'user.email'() {
            this.$v.user.email.$touch();
            if (this.formErrors.email) this.$delete(this.formErrors, 'email');
        },
        'user.organizations'() {
            this.$v.user.organizations.$touch();
        },
        'user.user_profile.contact_number'() {
            this.$v.user.user_profile.contact_number.$touch();
            if (this.formErrors.contact_number) this.$delete(this.formErrors, 'contact_number');
        }
    }, 
  
    validations() {
        return {
          user: this.validationRules
        };
    },
    mounted() {   
        if (this.componentType === 'STUDENT') {
          this.actGetOrganizationList(`?short=1&per_page=3000&page=1&sort=business_name`); 
        }
        setTimeout(()=> {
            this.userId && this.fetchUserData()
        },200)
        
    },
    created(){
        
    }
  }
  </script>
  
  <style>
    .image-card {
        padding: 20px;
    }
  </style>